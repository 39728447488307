import { INodeType } from '@site-mate/sitemate-flowsite-shared';

import { generateQuickbooksInvoiceMappings } from './quickbooks-invoice-mappings';
import { generateXeroInvoiceMappings } from './xero-invoice-mappings';
import { generateXeroTimesheetMappings } from './xero-timesheet-mappings';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mappingConfigs: Record<INodeType, (...args: any[]) => object> = {
  [INodeType.XERO_TIMESHEET_V1]: generateXeroTimesheetMappings,
  [INodeType.XERO_INVOICE_V1]: generateXeroInvoiceMappings,
  [INodeType.XERO_BILL_V1]: generateXeroInvoiceMappings,
  [INodeType.POWERBI_CONNECTOR_V1]: () => [],
  [INodeType.EXCEL_CONNECTOR_V1]: () => [],
  [INodeType.QUICKBOOKS_INVOICE_V1]: generateQuickbooksInvoiceMappings,
  [INodeType.MICROSOFT_SHAREPOINT_FORM_UPLOAD_V1]: () => [],
  [INodeType.HACKATHON_CASE_1]: () => [],
  [INodeType.HACKATHON_CASE_2]: () => [],
};
