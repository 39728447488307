import {
  IFlow,
  IFlowMetadataRequest,
  INodeType,
} from '@site-mate/sitemate-flowsite-shared';

import { generateHostedFileMetadata } from './hosted-file.metadata';
import { generateTriggerFilterMetadata } from './trigger-filter.metadata';

export const metadataMap: Record<
  INodeType,
  (flow: IFlow) => IFlowMetadataRequest
> = {
  [INodeType.XERO_TIMESHEET_V1]: generateTriggerFilterMetadata,
  [INodeType.XERO_INVOICE_V1]: generateTriggerFilterMetadata,
  [INodeType.XERO_BILL_V1]: generateTriggerFilterMetadata,
  [INodeType.POWERBI_CONNECTOR_V1]: generateHostedFileMetadata,
  [INodeType.EXCEL_CONNECTOR_V1]: generateHostedFileMetadata,
  [INodeType.QUICKBOOKS_INVOICE_V1]: generateTriggerFilterMetadata,
  [INodeType.MICROSOFT_SHAREPOINT_FORM_UPLOAD_V1]:
    generateTriggerFilterMetadata,
  [INodeType.HACKATHON_CASE_1]: generateTriggerFilterMetadata,
  [INodeType.HACKATHON_CASE_2]: generateTriggerFilterMetadata,
};
